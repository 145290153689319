@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/Graphik-Light.eot');
    src: url('../assets/fonts/Graphik-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Graphik-Light.woff2') format('woff2'),
        url('../assets/fonts/Graphik-Light.woff') format('woff'),
        url('../assets/fonts/Graphik-Light.ttf') format('truetype'),
        url('../assets/fonts/Graphik-Light.svg#Graphik-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/Graphik-Semibold.eot');
    src: url('../assets/fonts/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Graphik-Semibold.woff2') format('woff2'),
        url('../assets/fonts/Graphik-Semibold.woff') format('woff'),
        url('../assets/fonts/Graphik-Semibold.ttf') format('truetype'),
        url('../assets/fonts/Graphik-Semibold.svg#Graphik-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/Graphik-Bold.eot');
    src: url('../assets/fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Graphik-Bold.woff2') format('woff2'),
        url('../assets/fonts/Graphik-Bold.woff') format('woff'),
        url('../assets/fonts/Graphik-Bold.ttf') format('truetype'),
        url('../assets/fonts/Graphik-Bold.svg#Graphik-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/Graphik-Medium.eot');
    src: url('../assets/fonts/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Graphik-Medium.woff2') format('woff2'),
        url('../assets/fonts/Graphik-Medium.woff') format('woff'),
        url('../assets/fonts/Graphik-Medium.ttf') format('truetype'),
        url('../assets/fonts/Graphik-Medium.svg#Graphik-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/Graphik-Black.eot');
    src: url('../assets/fonts/Graphik-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Graphik-Black.woff2') format('woff2'),
        url('../assets/fonts/Graphik-Black.woff') format('woff'),
        url('../assets/fonts/Graphik-Black.ttf') format('truetype'),
        url('../assets/fonts/Graphik-Black.svg#Graphik-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/Graphik-RegularItalic.eot');
    src: url('../assets/fonts/Graphik-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Graphik-RegularItalic.woff2') format('woff2'),
        url('../assets/fonts/Graphik-RegularItalic.woff') format('woff'),
        url('../assets/fonts/Graphik-RegularItalic.ttf') format('truetype'),
        url('../assets/fonts/Graphik-RegularItalic.svg#Graphik-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/Graphik-Regular.eot');
    src: url('../assets/fonts/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Graphik-Regular.woff2') format('woff2'),
        url('../assets/fonts/Graphik-Regular.woff') format('woff'),
        url('../assets/fonts/Graphik-Regular.ttf') format('truetype'),
        url('../assets/fonts/Graphik-Regular.svg#Graphik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/Graphik-LightItalic.eot');
    src: url('../assets/fonts/Graphik-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Graphik-LightItalic.woff2') format('woff2'),
        url('../assets/fonts/Graphik-LightItalic.woff') format('woff'),
        url('../assets/fonts/Graphik-LightItalic.ttf') format('truetype'),
        url('../assets/fonts/Graphik-LightItalic.svg#Graphik-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/Graphik-Regular.eot');
    src: url('../assets/fonts/Graphik-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Graphik-Regular.woff2') format('woff2'),
        url('../assets/fonts/Graphik-Regular.woff') format('woff'),
        url('../assets/fonts/Graphik-Regular.ttf') format('truetype'),
        url('../assets/fonts/Graphik-Regular.svg#Graphik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
